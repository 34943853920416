class AllergenListButton {
  constructor() {
    this.button = document.querySelector('#allergenListBtn');
    this.content = document.querySelector('#allergenList');
    this.events();
  }

  events() {
    this.button.addEventListener('click', () => {
      this.content.classList.toggle('allergenList__items--open');
    });
  }
}

export default AllergenListButton;